import React from 'react'
import { H1, H3, P } from '../ui'

export function Title({ children, ...restProps }) {
  return (
    <H1 fontSize="3rem" textAlign="center" textTransform="uppercase" {...restProps}>
      {children}
    </H1>
  )
}

export function SectionTitle({ children, ...restProps }) {
  return (
    <H3 as="h3" fontSize="2.5rem" textAlign="center" textTransform="uppercase" {...restProps}>
      {children}
    </H3>
  )
}

export function ColTitle({ children, ...restProps }) {
  return (
    <H3
      marginTop="0"
      as="h3"
      fontSize="2rem"
      textAlign="left"
      textTransform="uppercase"
      {...restProps}
    >
      {children}
    </H3>
  )
}

export function PreTitle({ children, ...restProps }) {
  return (
    <P textAlign="center" color="gray" opacity="1" letterSpacing="1px" {...restProps}>
      {children}
    </P>
  )
}

export function PostTitle({ children, ...restProps }) {
  return (
    <P textAlign="center" color="secondary.800" opacity="1" letterSpacing="1px" {...restProps}>
      {children}
    </P>
  )
}
