import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Box } from '@chakra-ui/react'

import FormContact from '../components/formContact'
import Layout from '../components/layout'
import SocialLinks from '../components/socialLinks'

import { Article, Hero, Image, P, Section } from '../ui'
import { SectionTitle } from '../components/texts'
import DividerVertical from '../components/dividerVertical'

function ContactPage({ data }) {
  const { t } = useTranslation()

  const onSubmit = async (values, setSuccess) => {
    const body = values
    body.to = ''

    const res = await fetch(`${process.env.GATSBY_FORM_API}/api`, {
      headers: {
        Accept: 'application/json',
      },
      method: 'post',
      body: JSON.stringify(body),
    })

    if (res.ok) setSuccess(true)
    else setSuccess(false)
  }

  return (
    <Layout title={t('contact:title')}>
      <Hero
        justifyContent="center"
        minHeight={{ base: '50vh', md: '60vh' }}
        gallery={[
          <Image
            fluid={data.hero.childImageSharp.fluid}
            layout="background"
            style={{ opacity: 0.85 }}
          />,
        ]}
      />

      <Article display="flex" flexDirection={{ base: 'column', lg: 'row' }} minHeight="100vh">
        <Section flex="50%" spacing={6}>
          <SectionTitle textAlign="left">{t('contact:title')}</SectionTitle>
          <SocialLinks />
          {/* <Box
            as="iframe"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14917.679812925922!2d-89.8053381!3d20.8147417!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x19c69c95e76925eb!2shacienda%20poxila!5e0!3m2!1sen!2smx!4v1623466237305!5m2!1sen!2smx"
            width="100%"
            height="300px"
            frameBorder="0"
            style={{ border: '0' }}
            aria-hidden="false"
          /> */}
        </Section>

        <Section flex="50%" backgroundColor="secondary.800" color="white">
          <SectionTitle>{t('contact:intro.form.title')}</SectionTitle>
          <P textAlign="center">{t('contact:intro.form.subtitle')}</P>
          <DividerVertical />

          <Box boxShadow="0px 0px 10px rgba(0,0,0,0.1)" padding={4} backgroundColor="white">
            <FormContact
              color="initial"
              hideOnSuccess
              onSubmit={onSubmit}
              name={{
                label: t('contact:intro.form.name.label'),
                required: t('contact:intro.form.name.required'),
                invalid: t('contact:intro.form.name.invalid'),
                placeholder: t('contact:intro.form.name.placeholder'),
              }}
              email={{
                required: t('contact:intro.form.email.required'),
                invalid: t('contact:intro.form.email.invalid'),
                placeholder: t('contact:intro.form.email.placeholder'),
              }}
              message={{
                label: t('contact:intro.form.message.label'),
                invalid: t('contact:intro.form.message.invalid'),
                placeholder: t('contact:intro.form.message.placeholder'),
              }}
              successText={t('contact:intro.form.successText')}
              buttonText={t('contact:intro.form.sendMessage')}
            />
          </Box>
        </Section>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "contact/hero.png" }) {
      ...bannerSectionImage
    }
  }
`

export default ContactPage
